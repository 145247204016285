import cx from 'classnames';
import { forwardRef } from 'react';

import { twMerge } from '~/utils/twMerge';

import { Loader } from './loaders/Loader';

export type ButtonVariant =
  | 'legacy-muted'
  | 'legacy-neutral'
  | 'legacy-link'
  | 'legacy-link-text'
  | 'compact-dropdown-brand-ghost'
  | 'primary'
  | 'secondary'
  | 'tertiary';

export type ButtonSize = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export type ButtonColor = 'blue' | 'default' | 'danger' | 'success' | 'ram-green' | 'ram-yellow';
type ButtonShape = 'none' | 'square' | 'circle';

export interface ButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  type: React.ComponentPropsWithoutRef<'button'>['type']; // Make type explicit to avoid subtle errors
  loading?: boolean;
  variant?: ButtonVariant;
  size?: ButtonSize;
  shape?: ButtonShape;
  color: ButtonColor;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(function ButtonWithoutRef(
  {
    size = 'md',
    variant = 'primary',
    color = 'default',
    shape = 'none',
    className,
    loading,
    disabled,
    children,
    startIcon,
    endIcon,
    ...rest
  },
  ref,
) {
  const isLinkText = variant === 'legacy-link-text';
  const isLegacyVariant = variant.startsWith('legacy-');

  return (
    <button
      ref={ref}
      className={cx(
        getButtonClassNames({
          size,
          variant,
          shape,
          color,
          isLegacy: isLegacyVariant,
          isLinkText,
        }),
        className,
      )}
      disabled={loading || disabled}
      {...rest}
    >
      {loading ? <Loader variant="inherit" size="sm" /> : null}
      {startIcon ? startIcon : null}
      {children}
      {endIcon ? endIcon : null}
    </button>
  );
});

const classesByVariantAndColor: Record<ButtonVariant, Record<ButtonColor, string | string[]>> = {
  // Button
  primary: {
    default: [
      'bg-brand-blue border-brand-blue outline-brand-blue/10',
      'hocus:bg-blue-400',
      'aria-expanded:bg-blue-400 text-white',
    ],
    blue: [
      'bg-brand-blue border-brand-blue outline-brand-blue/10',
      'hocus:bg-blue-400',
      'aria-expanded:bg-blue-400 text-white',
    ],
    'ram-green': [
      'bg-brand-green border-0 outline-brand-green/10',
      'hocus:bg-coldgreen-700',
      'aria-expanded:bg-brand-green text-white',
    ],
    'ram-yellow': [
      'bg-brand-yellow border-0 outline-brand-yellow/10',
      'hocus:bg-yellow-300',
      'aria-expanded:bg-yellow text-black',
    ],
    danger: ['bg-red-500 border-red-500 outline-red-500/10', 'hocus:bg-red-400', 'aria-expanded:bg-red-400 text-white'],
    success: [
      'bg-green-400 border-green-400 outline-green-500/10',
      'hocus:bg-green-300',
      'aria-expanded:bg-green-300 text-black',
    ],
  },
  secondary: {
    default: ['text-black bg-white', 'hocus:bg-warmgray-50'],
    blue: ['text-brand-blue bg-white', 'hocus:bg-warmgray-50'],
    'ram-green': [],
    'ram-yellow': [],
    danger: ['text-red-500 bg-white', 'hocus:bg-warmgray-50'],
    success: ['text-warmgreen-500 bg-white', 'hocus:bg-warmgray-50'],
  },
  tertiary: {
    default: ['text-black', 'hocus:text-warmgray-400'],
    blue: ['text-brand-blue', 'hocus:text-blue-400'],
    danger: ['text-red-500 ', 'hocus:text-red-400'],
    'ram-green': [],
    'ram-yellow': [],
    success: ['text-warmgreen-500 ', 'hocus:text-warmgreen-400'],
  },
  'legacy-muted': { default: [], blue: [], 'ram-green': [], 'ram-yellow': [], danger: [], success: [] },
  'legacy-neutral': { default: [], blue: [], 'ram-green': [], 'ram-yellow': [], danger: [], success: [] },
  'legacy-link-text': { default: [], blue: [], 'ram-green': [], 'ram-yellow': [], danger: [], success: [] },
  'legacy-link': { default: [], blue: [], 'ram-green': [], 'ram-yellow': [], danger: [], success: [] },
  'compact-dropdown-brand-ghost': { default: [], 'ram-green': [], 'ram-yellow': [], blue: [], danger: [], success: [] },
};

const classesByVariant: Record<ButtonVariant, string | string[]> = {
  // Button
  primary: [
    'border outline-2 text-s-semibold whitespace-nowrap shadow-button-primary',
    'active:shadow-button-primary-active',
    'focus:outline focus:shadow-button-primary',
    'disabled:bg-inactive disabled:border-warmgray-100 disabled:cursor-not-allowed hocus:disabled:bg-inactive',
  ],
  secondary: [
    'border border-warmgray-100 text-s-semibold outline-2 outline-brand-blue/10 whitespace-nowrap shadow-button-secondary border-b-0 focus:shadow-button-secondary active:shadow-button-secondary-active',
    'focus:outline hover:before:hidden',
    'aria-expanded:before:hidden aria-expanded:outline',
    'disabled:text-inactive  hocus:border-warmgray-100 disabled:cursor-not-allowed',
  ],
  tertiary: ['text-s-semibold border border-transparent hocus:outline-none hocus:text-brand-blue'],

  // Dropdown
  'compact-dropdown-brand-ghost': [
    'text-s-semibold border border-transparent text-brand-blue hocus:outline-none hocus:bg-blue-50 hocus:text-blue-400',
  ],

  // Legacy
  'legacy-neutral': 'btn-neutral',
  'legacy-muted': '',
  'legacy-link': 'btn-link no-underline hocus:underline font-normal normal-case',
  'legacy-link-text': 'link link-hover link-brand-blue',
};

const legacyClassesBySize: Record<ButtonSize, string> = {
  xxs: 'btn-xxs',
  xs: 'btn-xs',
  sm: 'btn-sm',
  md: 'btn-md',
  lg: 'btn-lg',
  xl: 'btn-xl',
};

const classesBySize: Record<ButtonSize, string> = {
  xxs: 'px-1.5 py-[.1875rem] rounded-md leading-[1.125rem]', // 20px
  xs: 'px-1.5 py-px rounded-lg', // 24px
  sm: 'px-2 py-1 rounded-lg', //30
  md: 'px-2 py-[0.3125rem] rounded-lg', // 32px
  lg: 'px-2 py-[0.4375rem] rounded-lg', // 36px
  xl: 'px-2.5 py-[0.6875rem] rounded-lg', // 44px
};

const legacyClassesByShape: Record<ButtonShape, string> = {
  none: '',
  square: 'btn-square',
  circle: 'btn-circle',
};

const classesByShape: Record<ButtonShape, string> = {
  none: '',
  square: '',
  circle: 'rounded-full',
};

const squareLikeClassesOverridesBySize: Record<ButtonSize, string> = {
  xxs: 'p-[.1875rem]',
  xs: 'p-px',
  sm: 'p-1',
  md: 'p-[.3125rem]',
  lg: 'p-[0.4375rem]',
  xl: 'p-[0.6875rem]',
};

export const classesByShapeAndSize: Partial<Record<ButtonShape, Record<ButtonSize, string>>> = {
  square: squareLikeClassesOverridesBySize,
  circle: squareLikeClassesOverridesBySize,
};

const classesByVariantAndSize: Partial<Record<ButtonVariant, Record<ButtonSize, string>>> = {
  primary: {
    xxs: '',
    xs: '',
    sm: '',
    md: 'text-s-semibold',
    lg: 'text-s-semibold',
    xl: 'text-s-semibold',
  },
};

export function getButtonClassNames({
  variant,
  size,
  shape,
  isLegacy,
  isLinkText,
  color,
}: {
  variant: ButtonVariant;
  size: ButtonSize;
  shape: ButtonShape;
  color: ButtonColor;
  isLegacy: boolean;
  isLinkText?: boolean;
}) {
  if (isLegacy) {
    if (isLinkText) {
      return classesByVariant[variant];
    }

    return twMerge(
      'btn flex-nowrap',
      classesByVariant[variant],
      legacyClassesBySize[size],
      legacyClassesByShape[shape],
    );
  }
  const baseClasses = variant === 'tertiary' ? '' : 'inline-flex items-center gap-1 text-center justify-center';
  const sizeClasses = variant === 'tertiary' ? '' : classesBySize[size];

  return twMerge(
    baseClasses,
    classesByVariant[variant],
    classesByVariantAndColor[variant]?.[color],
    sizeClasses,
    classesByVariantAndSize[variant]?.[size],
    classesByShape[shape],
    classesByShapeAndSize[shape]?.[size],
  );
}
